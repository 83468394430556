import * as React from 'react';
import * as Styles from '../styles/pages/_faq.module.scss';
import * as CommonStyles from '../styles/global/_common.module.scss';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
import { BackGround } from '../components/background';
import { BreadCrumb } from '../components/breadcrumb';
import classNames from 'classnames';
import {Link, useIntl} from "gatsby-plugin-react-intl"

const FaqPage = ({ data, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  return (
    <Layout location={location}>
      <Seo title="よくある質問" description="Limitsのよくある質問です。" />
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
          {
            title: t('menu.firstLIMITS'),
          },
        ]}
        currentPage={t('menu.faq')}
      />
      <BackGround>
        <section className={Styles.faq}>
          <div className={classNames(Styles.faq__wrap, CommonStyles.container)}>
            <div>
              <h1>{t('menu.faq')}</h1>
            </div>
            <div className={Styles.faq__content}>
              <ul>
                <li>
                  <p className={Styles.faq__question}>{t('faq.whatIsLIMITS.title')}</p>
                  <div className={Styles.faq__box}>
                    <div className={Styles.faq__img}>
                      <StaticImage src="../images/FAQ_00.jpg" alt="" />
                    </div>
                    <div className={Styles.faq__answer}>
                      {t('faq.whatIsLIMITS.text1')}
                      <br />
                      {t('faq.whatIsLIMITS.text2')}
                      <br />
                      {t('faq.whatIsLIMITS.text3')}
                      <br />
                      {t('faq.whatIsLIMITS.text4')}
                      <br />
                      {t('faq.whatIsLIMITS.text5')}
                    </div>
                  </div>
                </li>
                <li>
                  <p className={Styles.faq__question}>{t('faq.whatEvent.title')}</p>
                  <div className={Styles.faq__box}>
                    <div className={Styles.faq__img}>
                      <StaticImage src="../images/FAQ_01.jpg" alt="" />
                    </div>
                    <div className={Styles.faq__answer}>
                      {t('faq.whatEvent.text1')}
                      <br />
                      {t('faq.whatEvent.text2')}
                    </div>
                  </div>
                </li>
                <li>
                  <p className={Styles.faq__question}>{t('faq.playerRegistration.title')}</p>
                  <div className={Styles.faq__box}>
                    <div className={Styles.faq__img}>
                      <StaticImage src="../images/FAQ_10.jpg" alt="" />
                    </div>
                    <div className={Styles.faq__answer}>
                      {t('faq.playerRegistration.text1')}
                      <br />
                      {t('faq.playerRegistration.text2')}
                      <br />
                      {t('faq.playerRegistration.text3')}
                      <br />
                      {t('faq.playerRegistration.text4')}
                      <br />
                      <a href="https://limits.jp/entry/" target="_blank">https://limits.jp/entry/</a>
                      <br />
                      {t('faq.playerRegistration.text5')}
                      <br />
                      {/*{t('faq.playerRegistration.text6')}*/}
                      {/*<br />*/}
                      {t('faq.playerRegistration.text7')}
                      <br />
                      <a href="https://youtu.be/7iRuaPOBWP0" target="_blank">https://youtu.be/7iRuaPOBWP0</a>
                      {/*<br />*/}
                      {/*{t('faq.playerRegistration.text8')}*/}
                      {/*<br />*/}
                      {/*<a href="https://limits.jp/roulettes" target="_blank">https://limits.jp/roulettes</a>*/}
                    </div>
                  </div>
                </li>
                <li>
                  <p className={Styles.faq__question}>{t('faq.limitation.title')}</p>
                  <div className={Styles.faq__box}>
                    <div className={Styles.faq__img}>
                      <StaticImage src="../images/FAQ_11.jpg" alt="" />
                    </div>
                    <div className={Styles.faq__answer}>
                      {t('faq.limitation.text1')}
                      <br />
                      {t('faq.limitation.text2')}
                      <br />
                      {t('faq.limitation.text3')}
                      <br />
                      {t('faq.limitation.text4')}
                    </div>
                  </div>
                </li>
                <li>
                  <p className={Styles.faq__question}>{t('faq.entryFee.title')}</p>
                  <div className={Styles.faq__box}>
                    <div className={Styles.faq__img}>
                      <StaticImage src="../images/FAQ_12.jpg" alt="" />
                    </div>
                    <div className={Styles.faq__answer}>
                      {t('faq.entryFee.text1')}
                      <br />
                      {t('faq.entryFee.text2')}
                      <br />
                      <a href="https://limits.jp/entry/" target="_blank">https://limits.jp/entry/</a>
                      <br />
                      {t('faq.entryFee.text3')}
                    </div>
                  </div>
                </li>
                <li>
                  <p className={Styles.faq__question}>{t('faq.requiredEquipment.title')}</p>
                  <div className={Styles.faq__box}>
                    <div className={Styles.faq__img}>
                      <StaticImage src="../images/FAQ_14.jpg" alt="" />
                    </div>
                    <div className={Styles.faq__answer}>
                      {t('faq.requiredEquipment.text1')}
                    </div>
                  </div>
                </li>
                <li>
                  <p className={Styles.faq__question}>{t('faq.availableSoftware.title')}</p>
                  <div className={Styles.faq__box}>
                    <div className={Styles.faq__img}>
                      <StaticImage src="../images/FAQ_15.jpg" alt="" />
                    </div>
                    <div className={Styles.faq__answer}>
                      {t('faq.availableSoftware.text1')}
                      <br />
                      {t('faq.availableSoftware.text2')}
                    </div>
                  </div>
                </li>
                <li>
                  <p className={Styles.faq__question}>{t('faq.watchEvent.title')}</p>
                  <div className={Styles.faq__box}>
                    <div className={Styles.faq__img}>
                      <StaticImage src="../images/FAQ_20.jpg" alt="" />
                    </div>
                    <div className={Styles.faq__answer}>
                      {t('faq.watchEvent.text1')}
                      <br />
                      {t('faq.watchEvent.text2')}
                    </div>
                  </div>
                </li>
                <li>
                  <p className={Styles.faq__question}>{t('faq.howToVote.title')}</p>
                  <div className={Styles.faq__box}>
                    <div className={Styles.faq__img}>
                      <StaticImage src="../images/FAQ_21.jpg" alt="" />
                    </div>
                    <div className={Styles.faq__answer}>
                      {t('faq.howToVote.text1')}
                      <br />
                      {t('faq.howToVote.text2')}
                      <br />
                      <a href="https://limits.jp/entry/" target="_blank">https://limits.jp/entry/</a>
                      <br />
                      {t('faq.howToVote.text3')}
                      <br />
                      {t('faq.howToVote.text4')}
                    </div>
                  </div>
                </li>
                <li>
                  <p className={Styles.faq__question}>{t('faq.pastEvent.title')}</p>
                  <div className={Styles.faq__box}>
                    <div className={Styles.faq__img}>
                      <StaticImage src="../images/FAQ_22.jpg" alt="" />
                    </div>
                    <div className={Styles.faq__answer}>
                      {t('faq.pastEvent.text1')}
                      <br />
                      {t('faq.pastEvent.text2')}
                      <br />
                      <a href="https://www.youtube.com/channel/UC1FVt17iyF6R-BS-2QxhtVQ" target="_blank">https://www.youtube.com/channel/UC1FVt17iyF6R-BS-2QxhtVQ</a>
                    </div>
                  </div>
                </li>
                {/*<li>*/}
                {/*  <p className={Styles.faq__question}>{t('faq.LimitsGoods.title')}</p>*/}
                {/*  <div className={Styles.faq__box}>*/}
                {/*    <div className={Styles.faq__img}>*/}
                {/*      <StaticImage src="../images/FAQ_23.jpg" alt="" />*/}
                {/*    </div>*/}
                {/*    <div className={Styles.faq__answer}>*/}
                {/*      {t('faq.LimitsGoods.text1')}*/}
                {/*      <br />*/}
                {/*      <a href="http://www.facto-store.com/?mode=cate&cbid=2263126&csid=0&sort=n" target="_blank">http://www.facto-store.com/ ?mode=cate&cbid=2263126&csid=0&sort=n</a>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</li>*/}
                <li>
                  <p className={Styles.faq__question}>{t('faq.stopMail.title')}</p>
                  <div className={Styles.faq__box}>
                    <div className={Styles.faq__img}>
                      <StaticImage src="../images/FAQ_30.jpg" alt="" />
                    </div>
                    <div className={Styles.faq__answer}>
                      {t('faq.stopMail.text1')}
                      <br />
                      {t('faq.stopMail.text2')}
                      <br />
                      {t('faq.stopMail.text3')}
                      <br />
                      {t('faq.stopMail.text4')}
                      <br />
                      {t('faq.stopMail.text5')}
                      <br />
                      {t('faq.stopMail.text6')}
                      <br />
                      {t('faq.stopMail.text7')}
                      <br />
                      {t('faq.stopMail.text8')}
                    </div>
                  </div>
                </li>
                <li>
                  <p className={Styles.faq__question}>{t('faq.mailAddressChange.title')}</p>
                  <div className={Styles.faq__box}>
                    <div className={Styles.faq__img}>
                      <StaticImage src="../images/FAQ_31.jpg" alt="" />
                    </div>
                    <div className={Styles.faq__answer}>
                      {t('faq.mailAddressChange.text1')}
                      <br />
                      {t('faq.mailAddressChange.text2')}
                      <br />
                      {t('faq.mailAddressChange.text3')}
                      <br />
                      {t('faq.mailAddressChange.text4')}
                      <br />
                      {t('faq.mailAddressChange.text5')}
                      <br />
                      {t('faq.mailAddressChange.text6')}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export default FaqPage;
